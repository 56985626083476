import React from "react";
import "./style.css";

const LocationComponent = ({ active, handleSelectCompany }) => {
  return (
    <>
      <div
        style={{
          padding: "0px !important",
          height: "0 !important",
        }}
        className={active ? "container-options-location" : "displayNone"}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <p
            className="text-location"
            onClick={() =>
              handleSelectCompany(
                "Av. Independencia 3050, CABA - San Jorge",
                "San Jorge"
              )
            }
          >
            Av. Independencia 3050, CABA - San Jorge
          </p>
          <div className="divider-locations"></div>
          <p
            className="text-location"
            onClick={() =>
              handleSelectCompany("Av. Warnes 2707, CABA - Tienda Auta", "Auta")
            }
          >
            Av. Warnes 2707, CABA - Tienda Auta
          </p>
          <div className="divider-locations"></div>
          <p
            className="text-location"
            onClick={() =>
              handleSelectCompany("Antonio Sastre 40 - Llavallol", "Lavallol")
            }
          >
            Antonio Sastre 40 - Llavallol
          </p>
        </div>
      </div>
    </>
  );
};

export default LocationComponent;

import React from "react";
import "./style.css";

const HoursComponent = ({
  activeHorario,
  horarios,
  seleccionarHorarios,
  diaSeleccionado,
}) => {
  const currentTime = new Date();
  const currentDate = currentTime.toLocaleDateString(); // Obtener la fecha actual en formato local (por ejemplo, "dd/mm/yyyy")
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  // Función que verifica si el horario ya ha pasado (solo si es el día de hoy)
  const isPastHorario = (horario) => {
    const [hours, minutes] = horario.split(":").map(Number);
    if (hours < currentHours) return true;
    if (hours === currentHours && minutes < currentMinutes) return true;
    return false;
  };

  return (
    <>
      <div className={activeHorario ? "container-hours" : "displayNone"}>
        {activeHorario && horarios !== undefined
          ? horarios.map((info, i) => {
              // Verifica si el día seleccionado es el día actual
              const isToday =
                diaSeleccionado?.toLocaleDateString() === currentDate;

              // Solo aplica la verificación de horarios pasados si es el día de hoy
              const isPast = isToday ? isPastHorario(info.horario) : false;
              return (
                <div key={i}>
                  <div
                    className="container-buttons-option-hours"
                    key={info.horario}
                    style={{
                      border: `1px solid ${
                        info.available && !isPast ? "#393839" : "#e2e2e2"
                      } `,
                      color: `${
                        info.available && !isPast ? "#393839" : "#e2e2e2"
                      } `,
                      cursor: `${
                        info.available && !isPast ? "pointer" : "auto"
                      } `,
                    }}
                  >
                    <div
                      onClick={() =>
                        info.available && !isPast
                          ? seleccionarHorarios(info.horario)
                          : null
                      }
                    >
                      {info.horario}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </>
  );
};

export default HoursComponent;
